import React, { useState, useEffect, useRef } from "react";
import "../css/DraggableWindow.css"; // Create this file for styles
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const DraggableWindow = ({ children }) => {
	const [position, setPosition] = useState({ x: 100, y: 100 });
	const [isDragging, setIsDragging] = useState(false);
	const windowRef = useRef(null);
	const margin = 20; // Margin from the corners

	const [isOpened, setIsOpened] = useState(false);

	const startDrag = (e) => {
		e.preventDefault();
		setIsDragging(true);

		const clientX = e.clientX || e.touches[0].clientX;
		const clientY = e.clientY || e.touches[0].clientY;

		windowRef.current.initialX = clientX - position.x;
		windowRef.current.initialY = clientY - position.y;
		document.body.style.userSelect = "none";
	};

	const onDrag = (e) => {
		if (!isDragging) return;

		const clientX = e.clientX || e.touches[0].clientX;
		const clientY = e.clientY || e.touches[0].clientY;

		const x = clientX - windowRef.current.initialX;
		const y = clientY - windowRef.current.initialY;

		setPosition({ x, y });
	};

	const endDrag = () => {
		setIsDragging(false);

		document.body.style.userSelect = "";
	};

	useEffect(() => {
		const handleMouseUp = () => endDrag();
		const handleMouseMove = (e) => onDrag(e);
		const handleTouchEnd = () => endDrag();
		const handleTouchMove = (e) => onDrag(e);

		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("mouseup", handleMouseUp);
		window.addEventListener("touchmove", handleTouchMove);
		window.addEventListener("touchend", handleTouchEnd);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
			window.removeEventListener("touchmove", handleTouchMove);
			window.removeEventListener("touchend", handleTouchEnd);
		};
	});

	return (
		<div
			ref={windowRef}
			className={isOpened ? "draggable-window" : "draggable-window hidden"}
			style={{ left: position.x, top: position.y }}
		>
			<div
				onTouchStart={startDrag}
				onMouseDown={startDrag}
				className="DargElem"
			></div>
			{isOpened ? (
				<FaAngleUp onClick={() => setIsOpened(!isOpened)} />
			) : (
				<FaAngleDown onClick={() => setIsOpened(!isOpened)} />
			)}
			{isOpened ? children : null}
			<div style={{ position: "absolute", top: "15px", right: "10px" }}>
				<label id="switch" class="switch">
					<input
						type="checkbox"
						defaultChecked={window.localStorage.getItem("theme") === "light"}
						onChange={() => {
							window.localStorage.setItem(
								"theme",
								window.localStorage.getItem("theme") === "light"
									? "dark"
									: "light"
							);
							document
								.querySelector("html")
								.setAttribute(
									"data-theme",
									window.localStorage.getItem("theme")
								);
						}}
						id="slider"
					/>
					<span class="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export default DraggableWindow;
