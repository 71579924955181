import React, { useState } from "react";
import "../css/MediaViewer.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const MediaViewer = ({ src, type }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	return (
		<div>
			{type === "image" ? (
				<img
					src={src}
					alt="Media"
					style={{ cursor: "pointer", maxWidth: "100%", maxHeight: "300px" }}
					onClick={handleOpen}
				/>
			) : (
				<video
					src={src}
					controls
					style={{ cursor: "pointer", maxWidth: "100%", maxHeight: "300px" }}
					onClick={handleOpen}
				/>
			)}

			{isOpen && (
                <TransformWrapper minScale={0.2} maxScale={5} smooth={true} disablePadding={true} limitToBounds={true}>
				<div className="MediaModal" onClick={handleClose}>
					<div className="ModalContent" onClick={(e) => e.stopPropagation()}>
						
							<TransformComponent>
								{type === "image" ? (
									<img
										src={src}
										alt="Media"
										className="Zoomable"
										style={{ width: "100vw", height: "100vh", objectFit: "contain"  }}
									/>
								) : (
									<video
										src={src}
										controls
										className="Zoomable"
										style={{ width: "100vw", height: "100vh", objectFit: "contain" }}
									/>
								)}
							</TransformComponent>
						
						<button onClick={handleClose}>Закрыть</button>
					</div>
				</div>
                </TransformWrapper>
			)}
		</div>
	);
};

export default MediaViewer;
