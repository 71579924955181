import { Route, Switch } from "react-router-dom";
import Panel from "./pages/Panel";
import Auth from "./pages/Auth";
import Admin from "./pages/Admin";

function Router() {
	return (
		<Switch>
            <Route exact path="/" ><Panel /></Route>
			<Route path="/panel">
				<Panel />
			</Route>
            <Route path="/auth">
				<Auth />
			</Route>
			<Route path="/admin">
				<Admin />
			</Route>
			<Route path="*">404</Route>
		</Switch>
	);
}

export default Router;
