import { useEffect, useState } from "react";
import { FaPaperclip, FaPaperPlane, FaXmark } from "react-icons/fa6";

function Overlay(props) {
	const [requisites, setRequisites] = useState("");

	const [bank, setBank] = useState("");

	function handleStatus(st) {
		var statusList = {
			paid: "Отправлено",
			1: "Ожидает оплаты",
			2: "Ожидает подтверждений сети",
			3: "Отправлено",
			4: "Отменен",
			"wait pay": "Ожидает отправки с нашей стороны",
			wait_pay: "Ожидает отправки с нашей стороны",
			wait: "Ожидает оплаты",
			locked: "Заблокирована по AML",
			bank_sending_wait: "В обработке банка",
			cancel: "Отменен",
			wrong_amount: "Оплачено меньше чем указано в заявке",
			paid_over: "Оплачено больше чем указано в заявке",
			invalid_requisites: "Невалидные реквизиты",
			successfull: "Отправлено",
		};

		return statusList[st] || st;
	}

	function checkManually() {
		fetch(`https://bot.yellowchanger.com/api/check_exchange_manual`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				exch_id: props.order,
			}),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				return res.json();
			})
			.then((res) => {
				props.triggerNotification(
					res.status === "sent"
						? "Перевод отправлен"
						: res.status === "notverifed"
						? "Клиент не прошёл верефикацию"
						: res.status === "not_paid"
						? "Оплата не найдена"
						: res.status
				);
			})
			.catch((e) => {
				console.log("error");
				props.triggerNotification("Ошибка при получении данных!");
			});
	}

	function updateRequisites() {
		fetch(`https://yellowchanger.com/api/set_new_requisites.php`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				exch_id: props.order,
				new_data: requisites,
				newbank: bank
			}),
		})
			.then(async (res) => {
				if (res.status !== 200) throw true;
				props.triggerNotification("Реквизиты изменены!");
				props.socket.emit("newRequisites", {
					requisites: requisites,
					order: props.order,
					
				});
			})
			.catch((e) => {
				console.log("error");
				props.triggerNotification("Ошибка при получении данных!");
			});

		setRequisites("");
		props.getPaymentInfo();
	}

	return (
		<>
			<div className="MessangerOverlay">
				<FaXmark
					onClick={() => props.setIsInfoOpened(false)}
					className="Close"
				/>

				<div className="OverlayInfo">
					{props.orderInfo.status ? (
						<p>Статус: {handleStatus(props.orderInfo.status)}</p>
					) : null}
					{props.orderInfo.direction ? (
						<p>Направление: {props.orderInfo.direction}</p>
					) : null}
					{props.orderInfo.get_amount ? (
						<p>Сумма: {props.orderInfo.get_amount}</p>
					) : null}
					{props.orderInfo.date_creation ? (
						<p>Дата создания: {props.orderInfo.date_creation}</p>
					) : null}
					{props.orderInfo.send_date ? (
						<p>Дата отправки: {props.orderInfo.send_date}</p>
					) : null}
					{props.orderInfo.send_to_address ? (
						<p>Адрес для оплаты: {props.orderInfo.send_to_address}</p>
					) : null}
					{props.orderInfo.get_to_address ? (
						<p>Адрес получения: {props.orderInfo.get_to_address}</p>
					) : null}
					{props.orderInfo.network_comission ? (
						<p>Комиссия сети: {props.orderInfo.network_comission}</p>
					) : null}
					{props.orderInfo.hash && props.orderInfo.hash !== "None" ? (
						<p>
							Хэш: <a href={props.orderInfo.hash}>{props.orderInfo.hash}</a>
						</p>
					) : null}
				</div>

				{props.orderInfo.status &&
				props.orderInfo.status === "invalid_requisites" ? (
					<>

						<select value={bank} onChange={(e) => setBank(e.target.value)}>
							<option value={""}></option>
							<option value={"sbppsb"}>СБП  ПСБ (Промсвязь) </option>
							<option value={"sbpakbars"}>СБП Ак-Барс  </option>
							<option value={"sbprnkb"}>СБП РНКБ  </option>
							<option value={"sbpotp"}>СБП ОТП </option>
							<option value={"sbpozon"}>СБП ОЗОН </option>
							<option value={"sbpmtc"}>СБП МТС </option>
							<option value={"sbppochtabank"}>СБП ПочтаБанк </option>
							<option value={"sbpumoney"}>СБП Юмани </option>
							<option value={"sbptinkoff"}>СБП Тинькофф </option>
							<option value={"sbpsber"}>СБП Сбер </option>
							<option value={"sbpraif"}>СБП Райфайзен </option>
							<option value={"sbpalfa"}>СБП Альфабанк </option>
							<option value={"sbpotkritie"}>СБП Открытие </option>
							<option value={"sbpvtb"}>СБП ВТБ </option>
							<option value={"sbpsovkombank"}>СБП Совкомбанк </option>
							<option value={"sbpgazprom"}>СБП Газпром </option>
							<option value={"sbprosbank"}>СБП Росбанк </option>
							<option value={"sber"}>СБЕР (Карта)</option>
						</select>
						<input
							value={requisites}
							onChange={(e) => {
								setRequisites(e.target.value);
							}}
							placeholder="Новые реквизиты"
						></input>
						<button
							onClick={() => {
								updateRequisites();
							}}
						>
							Сохранить
						</button>
					</>
				) : null}

				{props.orderInfo.status &&
				props.orderInfo.lolz &&
				(props.orderInfo.status === 4 ||
					props.orderInfo.status === "cancel") ? (
					<>
						<button
							className="Bottom"
							onClick={() => {
								checkManually();
							}}
						>
							Проверить вручную
						</button>
					</>
				) : null}
			</div>
		</>
	);
}

export default Overlay;
