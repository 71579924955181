import { useEffect, useState } from "react";
import "../css/MainWindow.css";
import Dialogs from "./Dialogs";
import useUser from "../hooks/useUser";
import { io } from "socket.io-client";

function Panel() {
	const [page, setPage] = useState(1);
	const user = useUser();

	useEffect(() => {
		if (user.loaded && user.error) {
			window.location.href = "/auth";
		}
	}, [user]);

	return (
		<>
			<div className="Main">
				<div className="Window">
					<div className="Content">
						<Dialogs user={user} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Panel;
