import { useEffect } from "react";
import Router from "./Router";
import useUser from "./hooks/useUser";



function App() {

  useEffect(() => {
    if(!window.localStorage.getItem("theme")) {
      window.localStorage.setItem("theme", "dark");
      document.querySelector("html").setAttribute("data-theme", "dark");
    } else {
      document.querySelector("html").setAttribute("data-theme", window.localStorage.getItem("theme"));
    }

  }, [])

  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;
