import {useEffect, useState} from "react";

let cache = { loaded: false, notInit: true };
export default function useUser(props) {
    let [user, setUser] = useState(cache);

    useEffect(() => {
        (async () => {
            if(cache?.notInit) {
                cache = fetch("/api/getUser", {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: "POST"
                }).then(r => r.json())
                    .then(r => ({ loaded: true, ...r }))
                    .catch(e => ({ loaded: true, error: false }));
            }
            setUser(await cache);
        })();
    }, []);

    return user;
}