function DialogList(props) {

	function getFormattedDateTime(date) {
		date = new Date(date);
		
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		
		return `${new Date().setUTCHours(new Date().getUTCHours() + 3) && Date.now() - date > 86400000 ? `${day}.${month}`: ""} ${hours}:${minutes}`;
	}
	return (
		<div className={props.activeDialog !== 0 ||  props.isInfoOpened  ? "DialogsNav" : "DialogsNav Mobile"} >
			{props.dialogs?.map((x, key) => (
				<div key={key}
					onClick={() => {
						props.socket.emit("unsubOldDialog", { id: props.activeDialog });
						props.setActiveDialog(x.tgId);
					}}
					className={props.activeDialog === x.tgId ? "Item Active" : "Item"}
				>
					<p className="Avatar">{x.name?.slice(0, 1)}</p>
					<div>
						<p className="Username">{x.name}</p>
						<p className="Text">{x.lastMessage?.source === "PANEL" ? props.supports.find(s => s.id === x.lastMessage.panelUserId) ? props.supports.find(s => s.id === x.lastMessage.panelUserId).displayName+": " : "Support: " : null}{x.lastMessage?.text ? x.lastMessage?.text : "Файл"}</p>
					</div>
					<p className="When">{getFormattedDateTime(x.lastMessage?.date)}</p>
					{x.lastMessage?.source === "TG" ? <p className="Unread"></p> : null}
				</div>
			))}
		</div>
	);
}

export default DialogList;
